@import 'theme/mixins.scss';

.tips {
  grid-area: advice;
}

.card {
  min-height: 200px;
  background: var(--color-white);
  border: 3px solid var(--color-primary);
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  position: relative;
  padding: 22px 0 0 22px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .noData {
    line-height: 50px;
  }

  h3 {
    color: var(--color-primary);
  }

  img {
    position: absolute;
    bottom: -6px;
    right: -6px;
  }
}

.contentExport {
  p {
    width: 70%;
    text-transform: uppercase;
    color: var(--color-primary);
    font-weight: bold;
  }

  .buttonExport {
    @include button(var(--color-yellow), auto, var(--color-primary), none, 5px);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 22px;
  }
}

.modal {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  background-color: white;
}