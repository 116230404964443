.card {
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 0 32px 0 32px;
  height: 100%;
  grid-area: retailers;

  h2 {
    text-align: left;
  }
}