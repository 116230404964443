.help {
  margin: 2.3rem 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3rem;

  &section:first-child {
    margin-bottom: 2rem;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: var(--color-black);
  }

  section {
    margin-bottom: 2rem;
  }

  p {
    font-size: 18px;
  }

  .downloadPdf {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    background-color: var(--color-white);
    padding: 0 1.6rem;
    display: flex;
    height: 86px;
    align-items: center;
    cursor: pointer;
    margin-top: 2rem;

    svg.iconHelp {  
      path {
        fill: var(--color-primary);
        stroke: var(--color-primary);
      }
    }

    b {  
      flex: 1;
      margin-left: 2.9rem;
      font-size: 24px;
      color: var(--color-black);
    }
    
    svg.iconArrow {
      background-color: var(--color-primary);
      padding: 0.6rem;
      width: 10px;
      height: 10px;
      border-radius: 50%;
  
      path {
        fill: var(--color-white);
      }
    }
  }

  .contact {
    background-color: var(--color-yellow);
    height: 86px;
    width: 60%;
    min-width: 260px;
    padding: 0 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: var(--color-black);
    justify-content: space-around;
    font-size: 24px;
    font-weight: 700;
  }
}