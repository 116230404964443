.card {
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 0 32px;
  height: 200px;
  overflow: auto;
  grid-area: news;

  h2 {
    text-align: left;
  }

  .noData {
    color: var(--color-primary);
    font-weight: bold;
    line-height: 119px;
  }
}

@media screen and (max-width: 1700px)
{
  .card
  {
    height: 100%;
  }
}