.gridContainer {
  display: grid;
  grid-template-columns: 105px 1fr;
  grid-template-rows: 100px 1fr;
  grid-template-areas:
    "sidenav header"
    "sidenav main";
  background-color: var(--color-primary);
  height: 100vh;
  overflow: hidden;
}

.main {
  grid-area: main;
  background-color: #FBFBFB;
  border-bottom-left-radius: 50px;
}
