@import 'theme/mixins.scss';

.vehicle {
  text-align: left;
  font-size: 24px;
  color: #303030;

  .brand {
    font-weight: bold;
    font-size: 48px;
    margin-top: 18px;
  }

  .model {
    font-size: 36px;
    font-weight: bold;
    padding-top: 1rem;
  }

  .dimensions {
    padding-top: 40px;
    font-size: 24px;
  }

}

.vehicleButtons {
  button {
    font-weight: bold;
    font-size: 18px;
    box-shadow: var(--box-shadow);

    &.buttonEdit {
      @include button(var(--color-white), 60px, var(--color-primary), 1px solid #27509B, 10px);
      flex: 1 1 0;
      margin-right: 1rem;
    }

    &.buttonShow {
      @include button(#D4DCEB, 60px, var(--color-primary), 0, 10px);
      flex: 1 1 0;
    }
  }
}

.optimalRangeOperation {
  margin: 5rem 0 2rem 0;

  h2 {
    margin-top: 1rem;
    color: var(--color-primary);
    text-align: left;
  }
}

.camberAngle {
  background: var(--color-grey-light);
  border-radius: 5px;
  padding: 1.7rem 1rem;

  .camberAnglTitle {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2rem;
    color: var(--color-primary);
  }
}