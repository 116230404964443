.camberAngleItem {
  position: relative;

  &.editable {
    .title {
      font-size: 24px;
      top: -30px;
      left: 96px;
    }

    .angle {
      font-weight: bold;
      font-size: 36px;
    }

    input {
      width: 50px;
      border: 1px solid var(--color-primary);
      text-align: center;
    }
  }

  .angle,
  .title {
    color: #27509B;
  }

  .title {
    font-size: 9px;
    position: absolute;
    right: 94px;
  }

  .angle {
    display: flex;
    align-items: flex-end;

    .unit {
      font-size: 15px;
      padding-top: 5px;
      align-self: end;
    }

    svg {
      cursor: pointer;
    }
  }
}