@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin hidden-visually() {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@mixin button($background-color, $height, $color, $border, $border-radius) {
  background-color: $background-color;
  border-radius: $border-radius;
  height: $height;
  line-height: $height;
  border: $border;
  cursor: pointer;
  color: $color;
}