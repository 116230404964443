@import 'theme/mixins.scss';

.container {
  background-color: #f7f8fb;
  box-shadow: 0px 10px 10px rgba(39, 80, 155, 0.15);

  position: relative;
  z-index: 1;
  .goBack {
    cursor: pointer;
    display: flex;
    align-items: center;

    .arrowBack {
      margin-right: 19px;

      path {
        fill: var(--color-primary);
      }
    }
  }

  input {
    border: 2px solid var(--color-white);
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white !important;
    height: 43px;
    font-size: 15px;
    padding-left: 16px;
    font-weight: 300;
    color: #858585;
    width: 300px;
    margin: 0 25px;
    box-shadow: var(--box-shadow);

    @media screen and (max-width: 1500px) {
      width: auto;
      max-width: 250px;
    }
  }

  .calendar {
    z-index: 10;
  }

  .goBack {
    cursor: pointer;
    display: flex;
    align-items: center;

    .arrowBack {
      margin-right: 19px;

      path {
        fill: var(--color-primary);
      }
    }
  }

  .buttonReset {
    @include button(transparent, auto, var(--color-primary), none, 10px);
    font-size: 16px;
  }
}
