.runInfos {
  grid-area: run;
  
  .title {
    color: var(--color-primary);
    font-size: 40px;
    letter-spacing: -0.3px;
    font-weight: bold;
  
    input {
      border: none;
      background: var(--color-grey-light);
      border-radius: 10px;
      padding: 6px 31px;
      font-size: 24px;
      width: 90%;
      font-weight: 400;
      color: var(--color-primary);
    }
  
    svg {
      cursor: pointer;
      margin-left: 1rem;
    }
  }
  
  .contentInfos {
    font-size: 18px;
  
    div {
      font-weight: 300;
      color: var(--color-grey-dark);
      letter-spacing: -0.3px;
  
      b,
      span {
        color: var(--color-primary);
      }
    }
  
    .contentInfosLeft div,
    .contentInfosRight div {
      margin-bottom: 6px;
    }
  
    .contentInfosRight svg {
      margin: 0 4px;
    }
  }
  
  .location svg {
    margin-right: 10px;
  }
}

