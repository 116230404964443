@import '../../../theme/mixins.scss';

.accountInformation {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 32px 60px 32px 32px;
  min-width: 40%;
  font-size: 24px;

  h2 {
    margin: 0;
    color: var(--color-primary);
    margin-bottom: 10px;
  }

  .form {
    label {
      color: var(--color-primary);
      font-weight: bold;
      display: flex;
      align-items: center;
      margin: 32px 0;
      justify-content: space-between;
    }

    span {
      font-weight: 400;
      display: flex;
      align-items: center;
      padding: 6px 0;
    }

    input {
      border: none;
      background: var(--color-grey-light);
      border-radius: 10px;
      padding: 6px 31px;
      font-size: 24px;
      width: 50%;
      font-weight: 400;
      color: var(--color-primary);
    }

    svg {
      cursor: pointer;
      margin-left: 1rem;
    }
  }

  button {
    @include button(var(--color-yellow), 70px, var(--color-black), none, 10px);
    text-transform: uppercase;
    box-shadow: var(--box-shadow);
    width: 250px;
    font-weight: bold;
    font-size: 18px;
  }
}