.sliderTags {
  margin-left: 5rem;
  max-height: 100px;
  border-bottom: 0.4rem solid transparent;

  .tag {
    bottom: 0;
    height: calc(100px - 0.4rem);
    //padding-top: 2.7rem;
    cursor: pointer;

    //width: 100px;

    &:focus {
      outline: none;
    }

    &.active {
      border-bottom: 0.4rem solid var(--color-primary);
    }

    svg {
      padding-right: 0.6rem;

      path {
        stroke: var(--color-primary);
        fill: var(--color-primary);
        opacity: 1;
        stroke-width: 0.1px;
      }
    }
  }

  // Custom slide
  .slide {
    //width: 100px;
    padding: 0.8rem 0.4rem 1rem;
    max-width: calc(175px - 0.8rem);
    bottom: 0;
    display: block;
    position: absolute;

    & div:first-child {
      font-size: 22px;
    }
  }
}
