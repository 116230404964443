.pagination {
  text-align: center;
  margin-top: 0.938rem;

  .paginationContent {
    display: flex;
    justify-content:center;

    li:first-child button {
      border-radius: 39px 0px 0px 39px;
      border-right: none;
    }

    li:last-child button {
      border-radius: 0 39px 39px 0;
        border-left: none;
    }

    .pageLink {
      position: relative;
      padding: .5rem .75rem;
      margin-left: -1px;
      line-height: 1.25;
      //color: #434343;
      cursor:pointer;
      border: 0.5px solid #434343;

      &.pageLinkNumbers {
        border-right-width: 0;
        border-left-width: 0;
        padding: .7rem .2rem .5rem .2rem;
      }
      }

    span {
      font-size: 11px;

      &.isActive {
        color: var(--color-primary);
        font-weight: bold;
      }
    }
  }

  button {
    background: transparent;
    border: none;
  }
}