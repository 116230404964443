.contentIconMedia svg,
.contentIconMedia .pause {
  margin-right: 26px;
  cursor: pointer;
}

.pause {
  background-color: var(--color-primary);
  width: 25px;
  height: 30px;
}