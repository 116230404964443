.label {
  font-size: 14px;
  color: var(--color-primary);
}

.uncheckedIcon,
.checkedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 9px;
  color: var(--color-primary);
}