@import 'theme/mixins.scss';

.title {
  margin-top: 40px;
}

.vehiclesDetails {
  h3 {
    margin: 50px 0;
  }
}

.contentActions {
  button {
    box-shadow: var(--box-shadow);
    width: 250px;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;

    &.buttonRemove {
      @include button(var(--color-white), 70px, var(--color-red), 1px solid var(--color-red), 10px);
    }

    &.buttonCancel {
      @include button(var(--color-white), 70px, var(--color-primary), 1px solid var(--color-primary), 10px);
    }

    &.buttonValide {
      @include button(var(--color-yellow), 70px, var(--color-black), none, 10px);
      margin-left: 40px;
    }
  }
}