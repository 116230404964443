.card {
  background: #ffffff;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  min-width: 535px;
  grid-area: realtime-pressure;
  height: fit-content;

  .noData {
    font-size: 17px;
    display: flex;
    line-height: 131px;
    color: var(--color-primary);
    font-weight: bold;
  }
}