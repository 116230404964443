.autocomplete {
  margin-left: auto;
  position: relative;

  &.open input {
    border-radius: 10px 10px 0 0;
  }

  .searchBar {
    position: relative;

    .iconSearch {
      position: absolute;
      top: 12px;
      padding-left: 16px;
    }
  }

  input {
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(39, 80, 155, 0.09);
    padding-left: 46px;
    color: var(--color-primary);
    font-size: 15px;
    margin: 0;
  }

  .options {
    border-top: 0.15px solid #858585;
    background-color: var(--color-white);
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 3px 6px rgba(39, 80, 155, 0.09);
    font-size: 12px;
    left: 25px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 0rem 1.2rem;
    position: absolute;
    width: 263px;
    z-index: 5;

    .section {
      padding-bottom: 0.37rem;
    }

    .section:first-child,
    .section:nth-child(2) {
      border-bottom: 1px solid rgba(133,133,133,0.25);
    }

    .title {
      color: var(--color-primary);
      font-weight: 600;
      padding: 0.87rem 0;
    }

    .noData {
      font-weight: bold;
      text-align: center;
    }

    a {
      color: var(--color-black);
      display: block;
      padding: 0.37rem 0;
      display: flex;
      align-items: center;

      svg {
        padding-right: 0.5rem;
        
        path {
          fill: var(--color-primary);
          stroke: var(--color-primary);
          opacity: 1;
        }
      }
    }
  }
}