@import 'theme/mixins.scss';

.container {
  h1 {
    font-family: 'Michelin Black';
    font-style: italic;
    font-weight: 900;
    color: var(--color-white);
    font-size: 28px;
    text-align: center;
  }

  .panelLeft {
    background-color: var(--color-primary);

    .imgLogo {
      margin: 3rem 0 3rem 0;
    }

    .imgIcon {
      margin: 9rem 0 0 0;
    }

    button {
      @include button(var(--color-yellow), 56px, black, 0, 10px);
      width: 200px;

      span {
        color: var(--color-grey);
        font-weight: bold;
        font-size: 18px;
      }
    }
  }

  .panelRight {
    flex: 4 2 auto;

    img {
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}