.pressure {
  padding: 5px;
  background: var(--color-grey-light);
  border-radius: 3px;
  margin: 1.5px 2px;
  min-height: 52px;
  min-width: 110px;

  &.pressureL {
    padding-left: 10px;
  }

  &.pressureR {
    padding-left: 15px;

  }

  .pressureTitle {
    letter-spacing: -0.3px;
    font-size: 9px;
    color: var(--color-primary);
  }

}