.parent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 35px;
  grid-row-gap: 22px;
  grid-template-areas: "run record vehicle"
    "run pressure vehicle"
    "realtime-pressure optimal advice"
    ". . advice";
  font-size: 15px;
  overflow-y: scroll;
  padding: 2.4rem 3.5rem;
  max-height: calc(100vh - 17.3rem);
  position: absolute;
  width: calc(100vw - 13.57rem);
}

.OptimalParent {
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 2rem;
  grid-area: optimal;
  height: fit-content;

  h2 {
    color: var(--color-primary);
    margin-top: 0;
    font-size: 18px;
  }

  div {
    text-align: center;
  }
}

@media screen and (max-width: 1730px) {
  .parent {
    grid-template-columns: 50% 50%;
    grid-template-areas: "run record"
      "run pressure"
      "realtime-pressure vehicle"
      "optimal vehicle"
      "advice vehicle";
  }
}