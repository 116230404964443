.contentPressure {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}