.card {
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
  margin: 10px 40px 10px 0;
  display: flex !important;
  padding: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: initial !important;

  &:focus {
    outline: none;
  }
}

.customSlider {
  min-width: 98%;

  .slick-track {
    margin: 0;
  }
}