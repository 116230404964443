:root {
  --color-primary: #27509b;
  --color-primary-medium: #35baf6;
  --color-secondary: #fce500;
  --color-white: #ffffff;
  --color-primary-light: #becae1;
  --color-grey: #434343;
  --color-grey-medium: #858585;
  --color-grey-light: #F4F7FB;
  --color-grey-dark: #323232;
  --color-yellow: #fce500;
  --color-red: #ff3737;
  --color-orange: #FA9928;
  --color-black: #303030;
  --color-green: #4caf50;
  --color-green-light: #6BBD4E;
  --box-shadow: 0px 2px 10px rgba(39, 80, 155, 0.15);
}

@font-face {
  font-family: 'Michelin';
  font-weight: 300;
  src: local('Michelin'), local('Michelin'),
    url('./fonts/Michelin/MichelinUnitText1-Regular.woff2') format('woff2'),
    url('./fonts/Michelin/MichelinUnitText1-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Michelin Black';
  src: local('Michelin Black'), local('Michelin Black'),
    url('./fonts/Michelin/MichelinUnitTitling1-Black.woff2') format('woff2'),
    url('./fonts/Michelin/MichelinUnitTitling1-Black.woff') format('woff');
}

* {
  font-family: 'Michelin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

ul {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  min-width: 1300px;
}

h1 {
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 2.3rem;
  letter-spacing: -0.3px;
  margin-top: 0;
}

h3 {
  margin: 0;
  color: var(--color-primary);
}

li {
  list-style: none;
  display: inline;

  a {
    text-decoration: none;
  }
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

.content {
  padding: 2.4rem 3.5rem;
  overflow-y: scroll;
  max-height: calc(100vh - 17.3rem);
  display: block;
  position: absolute;
  width: calc(100vw - 13.57rem);
}

// infinite-react-carousel custom for login page
.sliderCustom {
  position: relative;

  .carousel-row {
    height: 100vh;
  }

  .carousel-dots {
    position: absolute;
    bottom: 0;
    z-index: 1000;
    width: initial;
    right: 0;
    left: 0;
  }

  .carousel-dots li.carousel-dots-active button:before {
    color: var(--color-yellow) !important;
    font-size: 12px;
    opacity: 1;
  }

  .carousel-dots li button:before {
    color: var(--color-white) !important;
    font-size: 12px;
  }
}

svg g {
  opacity: 1;
}


//custom slider-slick
.slick-slider {
  width: 700px;

  margin-right: 30px;

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -25px;
  }

  .slick-disabled path {
    opacity: 0.34;
    stroke: var(--color-grey);
    fill: var(--color-grey);
  }

  svg.slick-arrow.slick-prev,
  svg.slick-arrow.slick-next {
    width: 5px;

    path {
      fill: var(--color-grey);
      stroke: var(--color-grey);
    }
  }
}

// customise rc-slider
.rc-slider-dot {
  display: none;
}

table {
  height: initial !important;
}

// customize tooltip .rc-slider
.rc-slider-mark {
  top: 25px !important;
}

.rc-slider-tooltip {
  top: 20px !important;

  .rc-slider-tooltip-inner {
    background: transparent;
    color: var(--color-primary);
    font-size: 12px;
    box-shadow: none;
  }
}

// custom graph
.apexcharts-xaxis-label {
  fill: var(--color-primary);
}

// custom mapbox
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left {
  display: none;
}

// custom panel switch
.switch-custom-panel {
  border-radius: 10px !important;

  &.checked .react-switch-handle {
    transform: translateX(31px) !important;
  }

  .react-switch-bg div {
    opacity: 1 !important;
    z-index: 10 !important;
  }

  .react-switch-handle {
    border-radius: 10px !important;
    width: 32px !important;
    height: 22px !important;
    z-index: 0 !important;
  }
}

// custom pdfviewer
.ReactModal__Content:focus {
  outline: none;
}

// react-modal
.Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(39, 80, 155, 0.15);
  z-index: 2;
}
