.dropdown {
  &:hover .dropdownList {
    visibility: visible;
  }

  .dropdownList {
    visibility: hidden;
    position: absolute;
    right: 55px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    z-index: 10;
  }
}