.card {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
}

.contentTire {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  .tireTitle {
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--color-primary);
  }

  .tireFl,
  .tireRl {
    text-align: left;
  }

  .tireFr,
  .tireRr {
    text-align: right;
  }

  .tireCold,
  .tireHot {
    font-size: 16px;
    color: var(--color-black);
    margin-top: 1.3rem;
  }

  .tire {
    background: rgba(39, 80, 155, 0.1);
    border-radius: 10px;
    padding: 1rem 2rem;
    margin: 0.5rem;

    b {
      font-size: 36px !important;
      color: var(--color-primary);

      span {
        font-size: 20px;
      }
    }

  }
}