.keyFigures {
  grid-area: keys;
}

.card {
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
  margin-right: 26px;
  height: 200px;

  &.recorded_sessions, 
  &.key_kilometers {
    flex: 3;
  }

  &.optimal_ro {
    flex: 5;
    margin-right: 0;
  }

  .key {
    font-weight: 800;
    font-size: 72px;
    line-height: 131px;
    color: var(--color-primary);

    .noData {
      font-size: 17px;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1700px)
{
  &.key_kilometers {
    flex: 25% !important;
  }
}