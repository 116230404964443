.menu {
  grid-area: sidenav;
  text-align: center;
  height: 100vh;
  .menuContent {
    max-height: calc(100vh - 120px);
  }

  a {
    color: #7D96C3;
    text-decoration: none;
    font-size: 15px;
  }

  img {
    padding-bottom: 6px;
  }

  svg {
    margin-top: 50px;

    path,
    svg g path {
      fill: #7D96C3;
      opacity: 1;
    }
  }


  & .selected {
    color: var(--color-white);
    font-size: 16px;

    .iconHelp path {
      stroke: var(--color-white);
    }

    svg path {
      fill: var(--color-white);
      opacity: 1;
    }
  }
}
