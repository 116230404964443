.card {
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 22px;
  min-width: 503px;
  grid-area: vehicle;

  .contentTop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-bottom: 32px;

    h3 {
      color: var(--color-primary);
    }

    .brand {
      color: #303030;
      font-size: 22px;
      padding-top: 12px;
    }

    .model {
      color: #303030;
      font-size: 16px;
      padding-bottom: 18px;
    }

    .tiresTitle {
      font-size: 12px;
      font-weight: 600;
      color: var(--color-primary);
    }

    .tire {
      color: #303030;
      font-size: 14px;
    }

    .camberAngle {
      background-color: var(--color-grey-light);
      border-radius: 10px;
      padding: 13px 0 0 0;
      font-size: 12px;

      h3 {
        text-align: center;
      }

      .number {
        font-size: 14px;
        color: var(--color-primary);
      }
    }
  }

  .contentTire {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 70px;
      height: 143px;
    }

    .tire {
      background: var(--color-grey-light);
      border-radius: 10px;
      margin: 0 0 5px 5px;
      padding: 17px;
      color: #303030;

      .tireTitle {
        font-size: 14px;
        color: var(--color-primary);
      }

      .numberGum {
        font-size: 24px;
        color: var(--color-primary);
      }

      .contentPressure b {
        color: var(--color-primary);
        font-size: 14px;

      }
    }
  }
}