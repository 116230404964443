.preferencesPanel {
  margin-right: 2rem;
  position: relative;

  &:hover .preferencesPanelList {
    visibility: visible;
  }

  .preferencesPanelList {
    visibility: hidden;
    position: absolute;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    padding: 20px 20px 12px 20px;
    width: 274px;
    right: 0;
    z-index: 10;
  }
}