.gauge {
  position: relative;
  height: 50px;
  width: 100px;
  overflow: hidden;
  background-color: #fff;
  color: var(--color-green-light);
  display: flex;

  .inner {
    position: absolute;
    background-color: #fff;
    width: 80px;
    height: 80px;
    top: 10px;
    left: 10px;
  }

  .outer {
    position: absolute;
    background-color: var(--color-green-light);
    width: 100px;
    height: 100px;
    padding: 0px 0px 0px 0px;
  }

  .outer, .inner {
    border-radius: 50%;
  }

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 50%;
    left: 0px;
    -ms-transform-origin: bottom center;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    background-color: white;
    opacity: 0.75;
  }

  .value {
    position: absolute;
    top: -1.2em;
    left: 0px;
    right: 0px;
    bottom: 0px;
    line-height: 1em;
    margin-top: 50%;
    font-weight: bold;
  }
}