.dimensionsOfTyre {
  .edit {
    border: 1px solid var(--color-primary);
    font-size: 15px;
    display: flex;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    margin-left: 0;
    color: #858585;
    align-items: center;

    svg {
      background-color: var(--color-primary);
      padding: 10px;
      width: 20px;
      border-radius: 50%;

      path {
        fill: var(--color-white);
      }
    }

    select {
      color: #858585;
      border: none;
      background: transparent;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

    }
  }

  svg {
    padding-left: 20px;
    cursor: pointer;
  }
}