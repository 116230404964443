.tireEssentialListItem {
  margin: 0 0 2.5rem 2rem;
  position: relative;
  border: 1px solid rgba(67, 67, 67, 0.5);
  cursor: pointer;

  img {
    object-fit: cover;
    mask-image: linear-gradient(to bottom, #FFFFFF 76.43%, rgba(196, 196, 196, 0.1) 95.04%);
    -webkit-mask-image: linear-gradient(to bottom, #FFFFFF 76.43%, rgba(196, 196, 196, 0.1) 95.04%);
  }

  .noImg {
    width: 100%;
    height: 100%;
    background-color: var(--color-grey-light);
  }
  
  .informations {
    position: absolute;
    bottom: 0;
    left: 1.6rem;
    color: var(--color-primary);

    b {
      font-size: 24px;
    }
  }
}