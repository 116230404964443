.label {
  font-size: 14px;
  color: var(--color-primary);
}

.select {
  background: var(--color-grey-light);
  border-radius: 10px;
  height: 24px;
  width: 64px;
  color: var(--color-primary);
  border:none;
  padding-left: 1rem;
  font-size: 9px;
  cursor:pointer;
}