.sliderStages {
  margin-left: 5rem;

  .stage {
    padding-top: 25px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.active {
      border-bottom: 6px solid var(--color-primary);
    }

    svg {
      padding-right: 10px;

      path {
        stroke: var(--color-primary);
        fill: var(--color-primary);
        opacity: 1;
        stroke-width: 0.1px;
      }
    }
  }

  // Custom slide
  .slide {
    width: 150px;
    padding: 12px 6px;

    & div:first-child {
      font-size: 22px;
    }

    & div:last-child {
      font-size: 14px;
      color: var(--color-primary)
    }
  }
}