@import 'theme/mixins.scss';

.form {
  margin: 2.9rem 2.4rem;

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-primary);
  }

  label {
    font-weight: bold;
  }

  textarea {
    width: 100%;
    height: 200px;
    margin: 2rem 0;
    padding: 1rem 0 0 1rem;

    &::placeholder {
      font-size: 14px;
      color: var(--color-grey-medium);
    }
  }
  
  input{
    @include button(var(--color-yellow), auto, var(--color-black), none, 5px);
    text-transform: uppercase;
    font-weight: bold;
    padding: 12px 0px;
    width: 100%;
  }
}