.session {
  display: flex;
  justify-content: space-around;
  padding: 20px 10px;
  margin: 10px 0;
  background-color: #F9FAFD;
  text-align: left;
  border-radius: 10px;

  .sessionRally>div,
  .sessionVehicle>div {
    display: flex;
    align-items: center;
  }

  .sessionTitle {
    font-weight: 800;
    font-size: 28px;
    letter-spacing: -0.3px;
    color: var(--color-primary);
  }

  .sessionLocation,
  .sessionDate {
    font-size: 14px;
  }

  .sessionRally,
  .sessionVehicle,
  .stages {
    svg {
      padding-right: 10px;

      path {
        stroke: var(--color-primary);
        fill: var(--color-primary);
        opacity: 1;
        stroke-width: 0.1px;
      }
    }
  }

  .sessionRally svg {
    width: 19px;
  }

  .sessionRally div,
  .sessionVehicle div {
    padding: 7px 0;
  }

  .sessionVehicleTitle {
    font-size: 20px;
  }

  .sessionVehicle {
    svg {
      width: 34px;

      path {
        stroke-width: 2px;
      }
    }
  }

  .sessionVehicleTire {
    font-size: 14px;

    svg {
      width: 12px;
      height: 14px;
      padding-left: 20px;
    }
  }
}


// Custom slide
.slide {
  background-color: #E8EEF6;
  width: 100px;
  border-radius: 10px;
  padding: 12px 6px;

  & div:first-child {
    padding-bottom: 12px;
    font-size: 14px;
  }

  & div:nth-child(2) {
    font-size: 10px;
  }

  & div:nth-child(3) {
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 20px;
  }

  & div:last-child {
    font-weight: 800;
    font-size: 14px;
    color: var(--color-primary);
    display: flex;
    align-items: center;

    svg {
      padding-right: 6px;
    }
  }
}