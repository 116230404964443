@import 'theme/mixins';

.card {
  height: 100%;
  background-color: var(--color-white);
  padding: 0 50px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
  overflow: scroll;
  grid-area: sessions;

  .button_sessions {
    @include button(var(--color-grey-light), 26px, var(--color-black), none, 5px);
    font-size: 10px;
    padding: 0.4rem 0.75rem;
    display: flex;
    align-items: center;

    svg {
      display: inline !important;
    }
  }

  .button_refresh {
    margin: 1rem;
    background-color: var(--color-yellow);
    padding: 0.5rem 2rem;
    border-radius: 5px;
    border-width: 0;
  }

  .noDataTitle,
  .noDataText {
    color: var(--color-primary);
    padding: 1rem 0;
  }

  .noDataTitle {
    font-size: 18px;
    font-weight: bold;
  }

  .noDataText {
    font-size: 14px;
  }
}

@media screen and (max-width: 1700px)
{
  .card
  {
    height: 430px;
  }
}