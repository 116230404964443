.autocomplete {
  margin-left: auto;
  position: relative;

  &.open input {
    border-radius: 10px 10px 0 0;
  }

  .searchBar {
    position: relative;

    .iconSearch {
      position: absolute;
      top: 12px;
      padding-left: 16px;
    }
  }

  input {
    border-radius: 10px;
    width: 100%;
    font-size: 15px;
    margin-bottom: 1.3rem;
    background-color: var(--color-grey-light);
    border: 2px solid var(--color-grey-light);
    box-sizing: border-box;
    height: 43px;
    padding-left: 16px;
    font-weight: 300;
    color: var(--color-primary);
    padding-left: 43px;
  }

  .options {
    border-top: 0.15px solid var(--color-primary);
    background-color: var(--color-grey-light);
    border-radius: 0px 0px 10px 10px;
    box-shadow: var(--box-shadow);
    font-size: 12px;
    top: 43px;
    max-height: 300px;
    overflow-y: scroll;
    padding: 0rem 1.2rem;
    position: absolute;
    width: 87%;
    z-index: 5;

    div {
      padding: 0.4rem 0;
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}