.item {
  background-color: var(--color-grey-light);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  margin-bottom: 10px;

  .content {
    margin-left: 1rem;

    .description {
      font-size: 12px;
    }

    .title {
      font-size: 16px;
      color: var(--color-black);
      font-weight: 700;
    }
  }



  svg path {
    fill: var(--color-primary);
  }
}