.card {
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  color: #303030;
  grid-area: record;

  .bestChrono {
    padding-top: 20px;

    i {
      padding-left: 23px;
      font-family: 'Michelin Black';
      font-weight: 900;
      font-size: 38px;
      letter-spacing: -0.3px;
      color: var(--color-primary);
    }
  }

  .otherStages {
    text-align: right;

    span {
      font-size: 18px;
      padding-left: 8px;
      color: var(--color-primary);
    }
  }
}