.catalogListItem {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  background-color: var(--color-white);
  width: 27%;
  margin: 0.4rem 2.4rem 0.4rem 0px;
  padding: 0 26px;
  display: flex;
  flex-direction: row;
  height: 86px;
  align-items: center;
  cursor: pointer;

  svg.iconTire {  
      path {
        fill: var(--color-primary);
      }
  }

  .detail {
    flex: 1;
    margin-left: 2.9rem;

    span {
      font-size: 12px;
    }
  }

  svg.iconArrow {
    background-color: var(--color-primary);
    padding: 0.6rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    path {
      fill: var(--color-white);
    }
  }
}