.homepage {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 26px;
  grid-row-gap: 28px;
  grid-template-areas: "keys news"
                      "sessions retailers";

  h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1700px)
{
  .homepage
  {
    height: auto;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:  "keys keys"
                        "sessions sessions"
                        "news retailers";
  }
}