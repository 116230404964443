.card {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
}

.detail {
  .label {
    margin-left: 1rem;
    font-size: 24px;
    color: var(--color-primary);
  }

  .dimensionsOfTyre svg {
    padding-left: 20px;
    cursor: pointer;
  }

  .edit {
    border: 1px solid var(--color-primary);
    font-size: 15px;
    display: flex;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    margin-left: 0;
    color: #858585;
    align-items: center;

    svg {
      background-color: var(--color-primary);
      padding: 10px;
      width: 20px;
      border-radius: 50%;

      path {
        fill: var(--color-white);
      }
    }

    select {
      color: #858585;
      border: none;
      background: transparent;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

    }
  }
}

.optimalRangeOperation {
  margin: 0;
  padding: 0 25px 25px 25px;

  h2 {
    text-align: left;
    font-weight: bold;
    color: var(--color-primary);
  }
}