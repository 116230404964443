.card {
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  color: var(--color-black);
  padding: 22px 22px 0 22px;
  grid-area: pressure;

  .noData {
    font-size: 17px;
    display: flex;
    line-height: 131px;
    color: var(--color-primary);
    font-weight: bold;
  }

  .legend {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }

    .legendItem {
      padding: .2rem 1rem;
      font-size: 10px;
      margin: 1.5px 2px;
    }

    .legendItemAVG {
      color: var(--color-white);
      background-color: var(--color-primary);
    }

    .legendItemAVD {
      color: var(--color-primary);
      background-color: var(--color-yellow);
    }

    .legendItemARG {
      color: var(--color-white);
      background-color: var(--color-primary-light);
    }

    .legendItemARD {
      color: var(--color-white);
      background-color: #E91E63;
    }
  }
}