.runs {
  td:last-child {
    text-align: right;
    padding: 1rem 3rem 1rem 0;
  }

  .nextArrow path {
    fill: var(--color-primary);
    stroke: var(--color-primary);
  }
}