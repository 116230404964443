.document {
  box-shadow: var(--box-shadow);

  .pagination {
    background: white;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    text-align: center;

    button:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      width: 44px;
      height: 44px;
      background-color: var(--color-white);
      border: 0;
      font-size: 0.8em;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}