.title {
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.3px;
  color: #303030;
}

.noData {
  font-size: 17px;
  display: flex;
  line-height: 131px;
  color: var(--color-primary);
  font-weight: bold;
}